import errorMessages from "@/setup/errorMsgs.json";
export default {
  data() {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    langOptions() {
      return this.$store.state.languages
    },
    selectedLangName() {
      return this.$store.state.languages.find(item => parseInt(item.id) === parseInt(this.$store.state.language_id)).name
    },
    selectedLangCode() {
      return this.$store.state.languages.find(item => parseInt(item.id) === parseInt(this.$store.state.language_id)).code
    },
    loginShow(){
      return parseInt(this.$store.state.loginShow) === 1 ? true : false
    },
    TimeStamp() {
      return Math.floor(Date.now() / 1000);
  }  
  },
  methods: {
    GTtrackGA4(dataLayerOptions) {
       //console.log("GTtrackGA4 track " , dataLayerOptions);
      try {
        window.dataLayer.push(dataLayerOptions);
      } catch (e) {
        console.log("not found dataLayer")
      }
    },
    deleteMetaTags() {
      //clear the meta tags

      document.title = ''

      let metaDesc = document.querySelectorAll('meta[name="description"]')

      if (metaDesc.length > 0) {
        let elem = metaDesc[0]
        elem.parentNode.removeChild(elem)
      }

      let metaKeywords = document.querySelectorAll('meta[name="keywords"]')

      if (metaKeywords.length > 0) {
        let elem = metaKeywords[0]
        elem.parentNode.removeChild(elem)
      }

      let metaOgTitle = document.querySelectorAll('meta[property="og:title"]')

      if (metaOgTitle.length > 0) {
        let elem = metaOgTitle[0]
        elem.parentNode.removeChild(elem)
      }

      let metaOgType = document.querySelectorAll('meta[property="og:type"]')

      if (metaOgType.length > 0) {
        let elem = metaOgType[0]
        elem.parentNode.removeChild(elem)
      }

      let metaOgSiteName = document.querySelectorAll('meta[property="og:site_name"]')

      if (metaOgSiteName.length > 0) {
        let elem = metaOgSiteName[0]
        elem.parentNode.removeChild(elem)
      }

      let metaOgUrl = document.querySelectorAll('meta[property="og:url"]')

      if (metaOgUrl.length > 0) {
        let elem = metaOgUrl[0]
        elem.parentNode.removeChild(elem)
      }

      let metaogImage = document.querySelectorAll('meta[property="og:image"]')

      if (metaogImage.length > 0) {
        let elem = metaogImage[0]
        elem.parentNode.removeChild(elem)
      }

      let metaOgImageWidth = document.querySelectorAll('meta[property="og:image:width"]')

      if (metaOgImageWidth.length > 0) {
        let elem = metaOgImageWidth[0]
        elem.parentNode.removeChild(elem)
      }

      let metaOgImageHeight = document.querySelectorAll('meta[property="og:image:height"]')

      if (metaOgImageHeight.length > 0) {
        let elem = metaOgImageHeight[0]
        elem.parentNode.removeChild(elem)
      }

      let metaOgDesc = document.querySelectorAll('meta[property="og:description"]')

      if (metaOgDesc.length > 0) {
        let elem = metaOgDesc[0]
        elem.parentNode.removeChild(elem)
      }
    },
    setMetaTags(metaTags) {
      //clear the meta tags - need this because duplicate data call - example products category list page
      this.deleteMetaTags()

      // this.setMetaTags({
      //     title: title,
      //     desc: desc,
      //     keywords:keywords,
      //     ogType:ogType,
      //     ogTitle:ogTitle,
      //     ogImage:ogImage,
      //     ogImageWidth:ogImageWidth,
      //     ogImageHeight:ogImageHeight,
      //     ogDesc:ogDesc
      // })


      //add page title
      if (metaTags.title) {
        document.title = metaTags.title
      } else {
        document.title = 'NT Élelmiszertermelő és Kereskedelmi Kft.'
      }

      //add meta tags
      if (metaTags.desc) {
        let metaDesc = document.createElement('meta')
        metaDesc.name = "description"
        metaDesc.content = metaTags.desc
        document.getElementsByTagName('head')[0].appendChild(metaDesc);
      } else {
        let metaDesc = document.createElement('meta')
        metaDesc.name = "description"
        metaDesc.content = "Az NT Élelmiszertermelő és Kereskedelmi Kft. a cseh AGROFERT cégcsoport magyarországi tagja, amely finomított napraforgó étolajat gyárt és forgalmaz Kiskunfélegyházán."
        document.getElementsByTagName('head')[0].appendChild(metaDesc);
      }

      //meta keywords
      if (metaTags.keywords) {
        let metaKeywords = document.createElement('meta')
        metaKeywords.name = "keywords"
        metaKeywords.content = metaTags.keywords
        document.getElementsByTagName('head')[0].appendChild(metaKeywords);
      }

      //og:type
      if (metaTags.ogType) {
        let metaOgType = document.createElement('meta')
        metaOgType.setAttribute('property', 'og:type')
        metaOgType.content = metaTags.ogType
        document.getElementsByTagName('head')[0].appendChild(metaOgType);
      }

      //og:title
      if (metaTags.ogTitle) {
        let metaOgTitle = document.createElement('meta')
        metaOgTitle.setAttribute('property', 'og:title')
        metaOgTitle.content = metaTags.ogTitle
        document.getElementsByTagName('head')[0].appendChild(metaOgTitle);
      } else {
        let metaOgTitle = document.createElement('meta')
        metaOgTitle.setAttribute('property', 'og:title')
        metaOgTitle.content = 'NT Élelmiszertermelő és Kereskedelmi Kft.'
        document.getElementsByTagName('head')[0].appendChild(metaOgTitle);
      }

      //og:url - FIX
      let metaOgUrl = document.createElement('meta')
      metaOgUrl.setAttribute('property', 'og:url')
      metaOgUrl.content = window.location
      document.getElementsByTagName('head')[0].appendChild(metaOgUrl);

      //og:image

      if (metaTags.ogImage) {
        let metaOgImage = document.createElement('meta')
        metaOgImage.setAttribute('property', 'og:image')
        metaOgImage.content = metaTags.ogImage
        document.getElementsByTagName('head')[0].appendChild(metaOgImage);


        //og:image:width
        if (metaTags.ogImageWidth) {
          let metaOgImageWidth = document.createElement('meta')
          metaOgImageWidth.setAttribute('property', 'og:image:width')
          metaOgImageWidth.content = metaTags.ogImageWidth
          document.getElementsByTagName('head')[0].appendChild(metaOgImageWidth);
        }

        //og:image:height
        if (metaTags.ogImageHeight) {
          let metaOgImageHeight = document.createElement('meta')
          metaOgImageHeight.setAttribute('property', 'og:image:height')
          metaOgImageHeight.content = metaTags.ogImageHeight
          document.getElementsByTagName('head')[0].appendChild(metaOgImageHeight);
        }

      } else {
        let metaOgImage = document.createElement('meta')
        metaOgImage.setAttribute('property', 'og:image')
        metaOgImage.content = process.env.VUE_APP_BASE_URL + '/images/brand/nt_fb_share.png'
        document.getElementsByTagName('head')[0].appendChild(metaOgImage);

        //og:image:width
        let metaOgImageWidth = document.createElement('meta')
        metaOgImageWidth.setAttribute('property', 'og:image:width')
        metaOgImageWidth.content = '600'
        document.getElementsByTagName('head')[0].appendChild(metaOgImageWidth);

        //og:image:height
        let metaOgImageHeight = document.createElement('meta')
        metaOgImageHeight.setAttribute('property', 'og:image:height')
        metaOgImageHeight.content = '315'
        document.getElementsByTagName('head')[0].appendChild(metaOgImageHeight);
      }

      //og:description
      if (metaTags.ogDesc) {
        let metaOgDesc = document.createElement('meta')
        metaOgDesc.setAttribute('property', 'og:description')
        metaOgDesc.content = metaTags.ogDesc
        document.getElementsByTagName('head')[0].appendChild(metaOgDesc);
      } else {
        let metaOgDesc = document.createElement('meta')
        metaOgDesc.setAttribute('property', 'og:description')
        metaOgDesc.content = "Az NT Élelmiszertermelő és Kereskedelmi Kft. a cseh AGROFERT cégcsoport magyarországi tagja, amely finomított napraforgó étolajat gyárt és forgalmaz Kiskunfélegyházán."
        document.getElementsByTagName('head')[0].appendChild(metaOgDesc);
      }


    },
    no_accents: function (s, opt) {
      s = String(s);
      // opt = Object(opt);

      var defaults = {
        'delimiter': '-',
        'limit': undefined,
        'lowercase': true,
        'replacements': {},
        'transliterate': true
      };

      opt = {}
      for (var k in defaults) {
        opt[k] = defaults[k];
      }

      var char_map = {
        // Latin
        'À': 'A', 'Á': 'A', 'Â': 'A', 'Ã': 'A', 'Ä': 'A', 'Å': 'A', 'Æ': 'AE', 'Ç': 'C',
        'È': 'E', 'É': 'E', 'Ê': 'E', 'Ë': 'E', 'Ì': 'I', 'Í': 'I', 'Î': 'I', 'Ï': 'I',
        'Ð': 'D', 'Ñ': 'N', 'Ò': 'O', 'Ó': 'O', 'Ô': 'O', 'Õ': 'O', 'Ö': 'O', 'Ő': 'O',
        'Ø': 'O', 'Ù': 'U', 'Ú': 'U', 'Û': 'U', 'Ü': 'U', 'Ű': 'U', 'Ý': 'Y', 'Þ': 'TH',
        'ß': 'ss',
        'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', 'æ': 'ae', 'ç': 'c',
        'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e', 'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',
        'ð': 'd', 'ñ': 'n', 'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o', 'ő': 'o',
        'ø': 'o', 'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u', 'ű': 'u', 'ý': 'y', 'þ': 'th',
        'ÿ': 'y',
      };

      // Make custom replacements
      for (let k in opt.replacements) {
        s = s.replace(RegExp(k, 'g'), opt.replacements[k]);
      }

      // Transliterate characters to ASCII
      if (opt.transliterate) {
        for (let k in char_map) {
          s = s.replace(RegExp(k, 'g'), char_map[k]);
        }
      }

      // Replace non-alphanumeric characters with our delimiter
      // var alnum = (typeof(XRegExp) === 'undefined') ? RegExp('[^a-z0-9]+', 'ig') : XRegExp('[^\\p{L}\\p{N}]+', 'ig');
      var alnum = RegExp('[^a-z0-9]+', 'ig')
      s = s.replace(alnum, opt.delimiter);

      // Remove duplicate delimiters
      s = s.replace(RegExp('[' + opt.delimiter + ']{2,}', 'g'), opt.delimiter);

      // Truncate slug to max. characters
      s = s.substring(0, opt.limit);

      // Remove delimiter from ends
      s = s.replace(RegExp('(^' + opt.delimiter + '|' + opt.delimiter + '$)', 'g'), '');

      return opt.lowercase ? s.toLowerCase() : s;
    },
    getSelectedLangFile(langCode) {
      let _this = this
      _this.get('lang/json_load/' + langCode, {})
        .then((resp) => {
          _this.$store.state.langText = resp.data
        }).catch(() => {
        console.log('lang file error')
      })
    },
    trText(key, defaultValue) {
      const langText = this.$store.state.langText;
      const langTextLocalStorage = JSON.parse(localStorage.getItem('langText'));
      if (!langText[key] && !langTextLocalStorage[key]) {
        langTextLocalStorage[key] = this.$store.state.languages.reduce((acc, lang) => {
          acc[lang.id] = `${lang.code}_${defaultValue}`
          return acc
        }, {});
        localStorage.setItem('langText', JSON.stringify(langTextLocalStorage))
      }

      if (langTextLocalStorage[key]) {
        return langTextLocalStorage[key][this.$store.state.language_id];
      } else {
        return langText[key];
      }
    },
    pad(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    },
    dateTimeToSqlFormat(date){
        var _date = date.getFullYear()+'-'+this.pad(date.getMonth()+1,2)+'-'+this.pad(date.getDate(),2)
        // var _time = this.pad(date.getHours(),2) + ":" + this.pad(date.getMinutes(),2) + ":" + this.pad( date.getSeconds(),2)
        return  _date
    
    },  
    timeToSqlFormat(time){ 
      // var _time = this.pad(time.getHours(),2) + ":" + this.pad(time.getMinutes(),2) + ":" + this.pad( time.getSeconds(),2)
      // return  _time
      
      let hh = String(time.HH)
      let mm = String(time.mm)
      return hh + ":" + mm 

    },   
    setErrorMsgs(err, refObserver){                  
      let _this = this
        return new Promise((resolve, reject) => {
          if (err) {            
            var ret  = {};
            for (var i in err.response.data.error){                         
              ret[i] = errorMessages[i][err.response.data.error[i]]
            }                                                  
            _this.$refs[refObserver].setErrors(ret);              
            resolve()
          } else {
            reject()
          }
          
        });
    },
    scrollToElement(element, diff){
            
        const offset = document.querySelector('.navbar').clientHeight + diff
        const bodyTop = document.body.getBoundingClientRect().top;
        const elementTop = element.getBoundingClientRect().top;
        const elementPos = elementTop - bodyTop;
        const offsetPos = elementPos - offset;

        window.scrollTo({
            top: offsetPos,
            behavior: 'smooth'
        });
    },
    formatPhoneNumber(event) {           
        //console.log("raw phone", event.replace(/\D/g, '').slice(0,9))
        let rawPhoneNum = event.replace(/\D/g, '').slice(0,9)
        
        this.phoneRaw = rawPhoneNum
        this.phoneShort = this.formatAsPhoneNumber(this.phoneRaw);                        
    },
    formatAsPhoneNumber(value) {
        value = value.replace(/\D/g, '');            
        if (value.length === 8) {            
            if (value.slice(0,1) == '1'){
              //Bp
              value = value.slice(0, 1) + '-' + value.slice(1, 4) + '-' + value.slice(4);                              
            } else {
              //videk
              value = value.slice(0, 2) + '-' + value.slice(2, 5) + '-' + value.slice(5);                              
            }
            
            //console.log("value BP", value)
        } else if (value.length === 9) {
            //mobil                
            value = value.slice(0, 2) + '-' + value.slice(2, 5) + '-' + value.slice(5);
            console.log("value mobil", value)
        }
        
        return value;
    },   
    doIframeSize(){             
      
        setTimeout(() => {          
          window.parent.postMessage(JSON.stringify(
            {
              action: "child-inner-height", 
              childHeight: document.body.scrollHeight              
            }
          ), this.$store.state.parentOrigin);    
        }, 0);
        

    }
  },
  mounted(){    
    
    const resizeObserver = new ResizeObserver(() => {         
      this.doIframeSize();
    });    
        
    resizeObserver.observe(document.body);            
    
  }
}

