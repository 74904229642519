<template>  
  <div>
    <router-view  />
  </div>  
</template>

<script>


export default {
  name: 'App',
  watch: {
    $route(to, from) {      
      console.log('app watch route')    
        if(this.$store.state.isMarketingCookieActive){          
            window.fbq('track', 'PageView'); 
        }

      window.parent.postMessage(JSON.stringify({action: "child-route-change"}), this.$store.state.parentOrigin);

      //change the iframe size
      this.doIframeSize()   
      
      if (to.name != from.name){
        window.parent.postMessage(JSON.stringify({action: "need-scroll-to-up"}), this.$store.state.parentOrigin);
      }
      
      // if (to.name == 'Home' && from.name != 'Home'){
      //   window.parent.postMessage(JSON.stringify({action: "go-to-home-page"}));
      // }
 
        window.dataLayer.push({
          'event': 'eventNavigation',
          'category': '',
          'action': '',
          'label': ''
        }); 

    }
  },
  created()  {
    let cookies = document.cookie;
    let arrayOfCookies = cookies.split(";");
    //console.log(z);
    for (let i = 0; i < arrayOfCookies.length; i++) {
        if(arrayOfCookies[i].indexOf("HEINEKEN_ENSIGHTEN_PRIVACY_Advertising") > -1){
            let c = arrayOfCookies[i].split("=");
            //console.log(c[1], typeof c[1]);
            if(c[1] === "1"){              
              console.log('HEPACookie yes')
                this.$store.state.isMarketingCookieActive = true;
            }else{              
              console.log('HEPACookie no')
                this.$store.state.isMarketingCookieActive = false;                
            }
        }
        /*else if(arrayOfCookies[i].indexOf("HEINEKEN_ENSIGHTEN_PRIVACY_Advertising") > -1){
            let c = arrayOfCookies[i].split("=");
            console.log(arrayOfCookies[i], c[1])
        }
        else if(arrayOfCookies[i].indexOf("HEINEKEN_ENSIGHTEN_PRIVACY_MODAL_VIEWED") > -1){
            let c = arrayOfCookies[i].split("=");
            console.log(arrayOfCookies[i], c[1])
        }*/
    }
           
        this.$store.dispatch("getSettings");
        this.$store.dispatch("getFileUrl")

        var _this = this;
        
        //window.parent.postMessage(JSON.stringify({action: "child-inner-height", childHeight: document.body.offsetHeight}));

        window.addEventListener('message', function(event) {
            console.log("Message received from the parent: " + event.data ); // Message received from parent

            let dataFromParent = JSON.parse(event.data);
            //console.log(dataFromParent, dataFromParent.hash, _this.$router);
            if(dataFromParent.action == 'email-megerosites'){
                _this.$router.push({name: "EmailVerification", params: { secret: dataFromParent.hash }});
            }else if(dataFromParent.action == 'elfelejtett-jelszo'){
                _this.$router.push({name: "NewPassword_hu", params: { secret: dataFromParent.hash }});
            }else if(dataFromParent.action == 'nyeremeny'){
                _this.$router.push({ path: 'nyeremeny', query: { hash: dataFromParent.hash , hash2: dataFromParent.hash2 }});
            } else if (dataFromParent.action == 'go-to-back-home'){
              setTimeout(() => {
                var element = document.getElementById(dataFromParent.element)
                element.scrollIntoView()       
              }, 1000);
            }
        });
                
  },
  mounted(){           
    console.log('pMsOrigin', this.$store.state.parentOrigin )
        window.parent.postMessage(JSON.stringify({action: "child-ready"}), this.$store.state.parentOrigin);
        //window.parent.postMessage(JSON.stringify({action: "child-ready"}));        
  } 
}
</script>