import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import * as CONFIG from '@/setup/init.js'

Vue.use(Vuex)

const timeStamp = Math.floor(Date.now() / 1000)

if (!localStorage.getItem('langText')) {
  localStorage.setItem('langText', '{}')
}

export default new Vuex.Store({
  state: {    
    token: false,
    //userType: 'visitor',
    user: false,       
    //shops: [],
    language_id: CONFIG.api_conf.defaultLanguageId,
    languages: CONFIG.api_conf.defLanguages,    
    langText: {},
    promoStatus: null, //before, live, after
    file_url: '',
    privacy_url: '',
   // products:[],
    loginShow: 1,
    daily_limit: 0,
    purchase_time_limit: 0,
    max_limit: 0,
    keep_recipe_date: null,
    questions: [],
    winnersAll: [],
    myDataKey: 0,
    isMarketingCookieActive: false, //init: false
    parentOrigin: process.env.VUE_APP_PARENT_ORIGIN.toString()
  },
  mutations: {
    getWinners(state, response) {
      state.winnersAll = response;
    },
    /*getShops(state, response) {
      state.shops = response;
    },
    getProds(state, response) {
      state.products = response;
    },*/
    setFileUrl(state, text) {
      state.file_url = text;
    },
    setDailyLimit(state, text) {
      state.daily_limit = text;
    },
    setPurchaseLimit(state, text) {
      state.purchase_time_limit = text;
    },
    setMaxLimit(state, text) {
      state.max_limit = text;
    },
    setRecipeDate(state, text) {
      state.keep_recipe_date = text;
    },
    setPrivacyUrl(state, text) {
      state.privacy_url = text;
    },
    setPromotionStatus(state, promoStatus) {
      state.promoStatus = promoStatus;
    },
    getQuestions(state, questions) {
      state.questions = questions;
    }
  },
  actions: {
    getQuestions({commit}) {
      return new Promise((resolve) => {
        axios.get(process.env.BASE_URL + 'questions.json?' + timeStamp).then(response => {
          commit("getQuestions", response.data)
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })
    },
    /*getShops({commit}) {
      return new Promise((resolve) => {
        axios.get(process.env.BASE_URL + 'data/shops.json?' + timeStamp).then(response => {
          commit("getShops", response.data.shops)
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })
    },
    getFileUrl({commit}) {
      return new Promise((resolve) => {
        axios.get(process.env.VUE_APP_BASE_URL + '/api/settings').then(response => {
          commit("setFileUrl",response.data.rules_file_link)
          commit("setPrivacyUrl",response.data.privacy_file_link)
          commit("setDailyLimit",response.data.limit_daly_applicant)
          commit("setPurchaseLimit",response.data.limit_purchase_time_applicant)
          commit("setMaxLimit",response.data.limit_user_max_applicant)
          commit("setRecipeDate",response.data.keep_recipe_date)
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })             
    }
    // getProducts({commit}) {
    //   return new Promise((resolve) => {
    //     axios.get(process.env.BASE_URL + 'data/products.json?' + timeStamp).then(response => {
    //       commit("getProds", response.data.products)
    //       resolve()
    //     })
    //       .catch(() => console.log('error getting endpoints'));
    //   })
    // }, */
    getWinners({ commit }) {
      return new Promise((resolve) => {
        axios.get(process.env.VUE_APP_API_URL + 'step1/winners').then(response => {
          commit("getWinners", response.data.winners)
  
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })
    },   
    getSettings({commit}) {
      return new Promise((resolve) => {
        axios.get(process.env.VUE_APP_API_URL + 'step1/status').then(response => {
          commit("setPromotionStatus",response.data.promo_status)
       
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })             
    },
    getFileUrl({commit}) {
      return new Promise((resolve) => {
        axios.get(process.env.VUE_APP_API_URL + 'settings').then(response => {
          commit("setFileUrl",response.data.rules_file_link)
          commit("setPrivacyUrl",response.data.privacy_file_link)
          resolve()
        })
          .catch(() => console.log('error getting endpoints'));
      })             
    }
  },
  modules: {
  }
})
